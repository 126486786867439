:root {
  --font-size-base-sp: 14;
  --font-size-base-pc: 16;
  --color-text-base: #1a1a1a;
  --color-main: #158864;
  --color-bule: #0d44ad;
  --color-light-blue: #eaf1fb;
  --font-family: "Manrope", -apple-system, "BlinkMacSystemFont", "Segoe UI", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Meiryo", "Montserrat", sans-serif;
  --font-family-en: "Manrope", sans-serif;
  --line-height-base: 2;
  --line-height-heading: 1.5;
  --letter-spacing-base: 0em;
  --letter-spacing-heading: 0.06em;
}

/* =========================================================
reset
========================================================= */
@tailwind base;
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-size: 100%;
  vertical-align: baseline;
  border: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after, q::before, q::after {
  content: "";
  content: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

a {
  text-decoration: normal;
  cursor: pointer;
}

input, select {
  vertical-align: middle;
}

strong {
  font-weight: normal;
}

/* =========================================================
base
========================================================= */
/* manrope-regular - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/manrope-v14-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
/* manrope-700 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/manrope-v14-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
/* manrope-700 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/manrope-v14-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
}
html {
  scrollbar-gutter: stable;
  font-size: calc(32/780 * 100vw);
}
@media (min-width:767px) {
  html {
    font-size: 100%;
  }
}

html, body {
  width: 100%;
  height: auto;
}

body {
  font-family: var(--font-family);
  font-weight: 400;
  line-height: var(--line-height-base);
  color: var(--color-text-base);
  letter-spacing: var(--letter-spacing-base);
  text-size-adjust: 100%;
  font-size: calc(16 / 16 * 1rem);
}
@media (min-width:767px) {
  body {
    font-size: calc(16 / 16 * 1rem);
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}
@media (min-width:767px) {
  img {
    /* stylelint-disable-next-line value-no-vendor-prefix */
    image-rendering: -webkit-optimize-contrast;
  }
}

svg {
  width: 100%;
  height: 100%;
  vertical-align: bottom;
}

a {
  color: var(--color-text-base);
  text-decoration: none;
}

figure {
  line-height: 0;
}

button {
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  line-height: var(--line-height-base);
  color: var(--color-text-base);
  text-align: left;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  line-height: var(--line-height-heading);
}

em {
  font-style: normal;
}

@tailwind components;
@tailwind utilities;
@layer components {
  .tw-cv-btn {
    @apply tw-text-center tw-text-sm md:tw-text-[1.125rem] tw-rounded-full tw-h-full tw-flex tw-justify-center tw-items-center tw-border-[1px] tw-border-solid tw-font-bold;
  }
}
.p-point {
  background-image: url("../img/point_bg01_sp.webp"), url("../img/point_bg02_sp.webp");
  background-repeat: no-repeat;
  background-position: top center, bottom center;
  background-size: 100% auto;
}
@media (min-width: 768px) {
  .p-point {
    background-image: url("../img/point_bg01_pc.webp"), url("../img/point_bg02_pc.webp");
    background-size: 1920px auto;
  }
}

.no-webp .p-point {
  background-image: url("../img/point_bg01_sp.png"), url("../img/point_bg02_sp.png");
  background-size: 100% auto;
}
@media (min-width: 768px) {
  .no-webp .p-point {
    background-image: url("../img/point_bg01_pc.png"), url("../img/point_bg02_pc.png");
    background-size: 1920px auto;
  }
}

.a-close-button {
  display: flex;
  align-items: center;
  color: #fff;
  background-color: var(--color-main);
}
.a-close-button::after {
  position: absolute;
  top: 50%;
  right: calc(10 / 16 * 1rem);
  display: block;
  width: calc(13 / 16 * 1rem);
  height: calc(3 / 16 * 1rem);
  content: "";
  background-color: currentcolor;
  border-radius: 9999px;
  transform: translateY(-50%);
}
.a-close-button::before {
  position: absolute;
  top: 50%;
  right: calc(10 / 16 * 1rem);
  display: block;
  width: calc(13 / 16 * 1rem);
  height: calc(3 / 16 * 1rem);
  content: "";
  background-color: currentcolor;
  border-radius: 9999px;
  transform: translateY(-50%) rotate(90deg);
}
@media (min-width: 768px) {
  .a-close-button::after {
    width: calc(8 / 16 * 1rem);
    height: calc(2 / 16 * 1rem);
  }
  .a-close-button::before {
    width: calc(8 / 16 * 1rem);
    height: calc(2 / 16 * 1rem);
  }
}

.is-open .a-close-button {
  color: var(--color-main);
  background-color: #efefef;
}
.is-open .a-close-button::before {
  transform: translateY(-50%) rotate(0);
}

@media (any-hover: hover) {
  .a-card-link p {
    position: relative;
  }
  .a-card-link p::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(3 / 16 * 1rem);
    content: "";
    background-color: var(--color-main);
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: scaleX(0);
  }
  .a-card-link:hover p::after {
    transform: scaleX(1);
  }
}
.p-cv {
  background: url("../img/cv_bg_sp.webp") center center no-repeat;
  background-size: cover;
}
@media (min-width: 768px) {
  .p-cv {
    background: url("../img/cv_bg_pc.webp") center center no-repeat;
    background-size: cover;
  }
}

.no-webp .p-cv {
  background: url("../img/cv_bg_sp.png") center center no-repeat;
  background-size: cover;
}
@media (min-width: 768px) {
  .no-webp .p-cv {
    background: url("../img/cv_bg_pc.png") center center no-repeat;
    background-size: cover;
  }
}

.a-link-button {
  position: relative;
  background-color: rgb(21, 136, 100);
}
.a-link-button::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: linear-gradient(to right, rgb(6, 114, 80) 0%, rgb(8, 158, 111) 100%);
  opacity: 0;
  transition: opacity 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

@media (any-hover: hover) {
  .a-link-button:hover::after {
    opacity: 1;
  }
}

/*# sourceMappingURL=style.css.map */